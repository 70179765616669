import React from "react"
import Layout from "../../components/layout-ages"
import { Link } from "gatsby"

export default () => (
  <Layout title="3 Year Old">
    <h2>3 Year Old</h2>
    <div className="clear-block">
      <div id="node-14" className="node">


        <div className="content clear-block">
          <p><img src="/images/009-mercy-300x225.JPG" className="float-right" width="300"
                  height="225" alt=""/> Yes, most three years olds can begin learning the basic elements of piano using the
            Animal Note Method. Not only will they be learning, they will love doing it. I suggest that, as a the
            parent, you should start your three year old using the “<Link to="/books/Beginning-Note-Reading">Note Reading
              for Children</Link>” and "<Link to="/flash-cards/Animal-Note-Standard-Note-Flash-Card-Set-Student-Size">Animal
              Note/Standard Note Flash Card Set-Grand Staff</Link>". Even if you have never had any music lessons yourself,
            the Animal Note method is designed so you can easily understand and teach the materials. However, three year
            olds are just starting to grow in their security of learning. If you can create a piano time of 5 minutes a
            day for 3 to 5 days per week, your child will learn quickly and look forward to the routine time and
            attention. A child this age does not need to work more than 15 minutes at any one time. Short periods, done
            more frequently, produce the best results. </p>
          <p>It is very important to be creative with them. If they don’t want to play at a lesson ask, them to teach
            their favorite toy what they have already learned. Help them find the words to explain the information to
            the toy. Sure, it isn’t easy to get the paw of a teddy bear, or the wheel of a favorite truck to hit a piano
            key, and sure, the hand of a baby doll is easier, but does it matter? No, what’s happening is that the child
            is learning by teaching. Even if the bear ends up hitting two keys, and you have to help the child decide
            which key is the one the paw should have struck, he/she is learning and you are spending quality time with
            your child.</p>
          <p>A Keyboard Chart comes with the book. It fits behind the black keys of most keyboards. A picture of each
            Animal Note is on the appropriate white key. Your very young student will first decide what note is pictured
            first on the music being studied, then locate its picture on the keyboard chart and follow it down to strike
            the note. If they have done so correctly, give them some form of praise (sometimes I make the noise the
            animal might make when speaking), then move on to the next animal pictured on the music. Do not be
            disappointed if the learning seems to be going slowly, especially at first. Most three year olds do not have
            a long attention and interest span. Review of songs already learned at the end of the lesson always brings a
            feeling of satisfaction and accomplishment.</p>
          <p>Use the flash cards for the notes you have covered, repeating the word clue and then showing them the
            standard note on the back side. Your child will then, from the beginning, tie the Animal Note to its
            standard note. The Flash Cards are also very helpful with this age group as a lesson can be spent with the
            child arranging the notes they have learned and then playing their “own composition.” They enjoy this and it
            is always a great review of the word clues and the Animals’ backside (standard note).</p>
          <p>Their hands will want to be in a fist and playing with only one finger at first. That is common, so ask
            them how a spider walks. Have them use their fingers like the spider walks to play the keys. It takes about
            a month before you begin to see progress with some 3 year olds; however, they are so ready to learn. Most
            started at this age will be playing simple standard music by the time they are six. They will love playing
            their Animal Note songs from the Fun books. </p>
          <p>Many teachers will not consider taking a child until they are 8 or in the third grade, unless the child can
            show that they already know how to use their hands correctly and can read music notes. As a child gets
            involved in all their studies at school and extra outside activities, their piano will already be a beloved
            part of their lives. That love will keep on growing. What a treasured gift you have given you child!</p>
          <p>The most important thing is: BE PATIENT, CREATIVE AND HAVE FUN – THEY WILL!</p>
          <h3>Testimonial</h3>
          <p>"Cole is doing fantastic!! I was so worried that he would not sit still or have a short attention span. He
            sits with rapt attention on his teacher. He says he wish he could have lessons EVERY DAY. When I call him to
            practice at his keyboard, he comes running."<br/>
            - Marcy</p>

        </div>

        <div className="clear-block">
          <div className="meta">
          </div>

        </div>

      </div>
    </div>
  </Layout>
)
